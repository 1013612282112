<!--  -->
<template>
  <div>
     <el-empty :image-size="200" :description="text"></el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text:''
    }
  },
  created() {
    this.text = this.$route.query.message
  }
}
</script>
<style lang='scss' scoped>
.el-empty{
  margin-top: 10%;
}
</style>